import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: _ctx.viewbox,
    height: "22px",
    width: "22px",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: _ctx.path }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}